<template>
    <div v-if="data" class="page-group-box clearfix auth-tips">
        <div v-if="data.status == 0 || !data.status" class="fl">
            <span class="icon el-icon-warning color-warning"></span> 
            您还未进行认证，可能无法使用某些核心功能，建议您尽快认证
        </div>
        <div v-if="data.status == 1" class="fl">
            <span class="icon el-icon-warning color-warning"></span> 
            当前认证信息正在等待审核，请耐心等待审核结果
        </div>
        <div v-if="data.status == 2" class="fl">
            <span class="icon el-icon-success color-success"></span> 
            您的认证信息已通过审核
        </div>
        <div v-if="data.status == 3" class="fl">
            <span class="icon el-icon-error color-danger"></span> 
            您的认证信息未通过审核（原因：{{data.remarks}}），请修改后重新提交审核
        </div>
        <el-button v-if="showButton" class="fr" type="primary" size="small" @click="authManage">认证管理</el-button>
    </div>
</template>


<script>
export default {
    props:{
        authData:{
            type:Object,
            default:null
        },
        showButton:{
            type:Boolean,
            default:true
        }
    },
    data(){
        return{
            data:null,
            userId: this.$store.state.userInfo.id
        }
    },
    created(){
        if( this.authData ){
            this.data = {...this.authData};
        }else{
            this.getClaimInfo();
        }
        
    },
    methods:{
        getClaimInfo(){
            this.$request({
                url: "/user/register/getClaimInfo",
                params: {userId:this.userId},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result
                    } else {
                        this.$message.error(msg || "获取认证信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取认证信息失败");
                })
                .finally(() => {
                    // this.dataLoading = false;
                });
        },
        authManage(){
            this.$router.push({
                path:'/manage/workbench/auth'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.auth-tips{
    line-height: 32px;
    padding: 12px 20px;
    margin-bottom: 10px;
    .icon{
        font-size: 14px;
    }
}
</style>