<template>
    <div>
        <auth-tips v-if="editType == 1 && reFresh" :authData.sync="this.formData" :showButton="false"></auth-tips>
        <el-form
            :model="formData"
            :rules="rules"
            ref="formData"
            label-position="top"
            v-loading="dataLoading"
        >
            <div :class="['group', editType == 1 ? 'page-group-box' : '']">
                <div class="page-group-title">认证信息</div>
                <el-form-item v-if="userType == 2" prop="authProperty" label="认证性质">
                    <el-radio-group v-model="formData.authProperty" size="medium" @change="$refs.formData.clearValidate()">
                        <el-radio :label="1" border>个人</el-radio>
                        <el-radio :label="2" border>企业</el-radio>
                    </el-radio-group>
                </el-form-item> 
                <template v-if="formData.authProperty == 2"> 
                    <el-row :gutter="24">
                        <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                            <el-form-item prop="companyName" :rules="[{required: true, message: '请填写企业名称'}]">
                                <span slot="label">企业名称 <small>请填写营业执照上的企业名称</small></span>
                                <el-input v-model="formData.companyName" placeholder="请填写企业名称" size="medium" maxlength="100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                            <el-form-item prop="licenseId" :rules="[{required: true, message: '请填写营业执照编号'}]">
                                <span slot="label">营业执照编号 <small>请填写营业执照上的统一社会信用代码</small></span>
                                <el-input v-model="formData.licenseId" placeholder="请填写营业执照编号" size="medium"  maxlength="100"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
					<el-row :gutter="24">
						<el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
							<el-form-item  prop="businessType" label="企业类型" :rules="[{required: true, message: '请选择企业类型'}]" >						    
								<el-select v-model="formData.businessType" placeholder="请选择企业类型" size="small">
									<el-option :value="0" label="有限责任公司（自然人投资或控股）"></el-option>
									<el-option :value="1" label="股份有限公司"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
							<el-form-item prop="registeredAddress" :rules="[{required: true, message: '请填写注册地址'}]"  label="注册地址">
								<el-input v-model="formData.registeredAddress" placeholder="请填写注册地址" size="medium"  maxlength="50"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
                    <el-row :gutter="24">
						<el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
							<el-form-item prop="registeredCapitalStr" label="注册资本">								
								<el-input-number  v-model="formData.registeredCapitalStr" :precision="2"
									controls-position="right" :min="0" :max="100000" size="medium"
									placeholder="请填写注册资本" style="width: 90%;"></el-input-number>  万元
							</el-form-item>
						</el-col>
                        <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                            <el-form-item prop="licensePic" :rules="[{required: true, message: '请上传营业执照'}]" :show-message="formData.licensePic ? false : true">
                                <span slot="label">营业执照 <small>请上传营业执照副本扫描图,需带有公章</small></span>
                                <image-upload 
                                    class="licensePic" 
                                    :url.sync="formData.licensePic" 
                                    :urlFull.sync="formData.licensePic">
                                </image-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    
                    <el-form-item prop="otherPic">
                        <span slot="label">额外补充信息 <small>比如开户许可证等证件扫描图</small></span>
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                                <image-upload 
                                    class="otherPic"
                                    :url.sync="otherPic1" 
                                    :urlFull.sync="otherPic1">
                                </image-upload>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                                <image-upload 
                                    class="otherPic"
                                    :url.sync="otherPic2" 
                                    :urlFull.sync="otherPic2">
                                </image-upload>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </template>    

                <template v-else>
                    <el-row :gutter="24">
                        <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                            <el-form-item prop="corporationName" :rules="[{required: true, message: '请填认证人姓名'}]">
                                <span slot="label">认证人姓名</span>
                                <el-input v-model="formData.corporationName" placeholder="请填认证人姓名" size="medium" maxlength="20"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                            <el-row :gutter="4">
                                <el-col :span="12">
                                    <el-form-item prop="corporationCodeName" :rules="[{required: true, message: '请填写证件类型'}]">
                                        <span slot="label">证件类型 <small>比如身份证</small></span>
                                        <el-input v-model="formData.corporationCodeName" placeholder="比如身份证" size="medium"  maxlength="20"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="corporationId" class="hide-required-asterisk" :rules="[{required: true, message: '请填写证件号码'}]">
                                        <span slot="label"> </span>
                                        <el-input v-model="formData.corporationId" placeholder="请填写证件号码" size="medium"  maxlength="20"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-form-item :prop="!formData.corporationIdPic1 ? 'corporationIdPic1' : 'corporationIdPic2'" 
                        :rules="[{required: true, message: '请上传认证人证件'}]" 
                        :show-message="formData.corporationIdPic1 && formData.corporationIdPic2 ? false : true"
                    >
                        <span slot="label">认证人证件 <small>请上传身份证或其他证件的正面和背面扫描图</small></span>
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                                <image-upload 
                                    class="otherPic"
                                    :url.sync="formData.corporationIdPic1" 
                                    :urlFull.sync="formData.corporationIdPic1">
                                    <span slot="image-slot">证件正面</span>
                                </image-upload>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                                <image-upload 
                                    class="otherPic"
                                    :url.sync="formData.corporationIdPic2" 
                                    :urlFull.sync="formData.corporationIdPic2">
                                    <span slot="image-slot">证件背面</span>
                                </image-upload>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item prop="otherPic">
                        <span slot="label">额外补充信息 <small>自愿上传额外的补充信息，以证明自己的身份</small></span>
                        <el-row :gutter="24">
                            <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                                <image-upload 
                                    class="otherPic"
                                    :url.sync="otherPic1" 
                                    :urlFull.sync="otherPic1">
                                </image-upload>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                                <image-upload 
                                    class="otherPic"
                                    :url.sync="otherPic2" 
                                    :urlFull.sync="otherPic2">
                                </image-upload>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </template>
            </div>
            
            <div v-if="formData.authProperty == 2" :class="['group',editType == 1 ? 'page-group-box MT10' : '']"> 
                <div class="page-group-title">法人代表</div>
                <el-row :gutter="24">
                    <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                        <el-form-item prop="corporationName">
                            <span slot="label">法人代表姓名</span>
                            <el-input v-model="formData.corporationName" placeholder="请填写法人代表姓名" size="medium" maxlength="20"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                        <el-form-item prop="corporationId">
                            <span slot="label">法人身份证</span>
                            <el-input v-model="formData.corporationId" placeholder="请填写法人身份证号码" size="medium" maxlength="20"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item prop="corporationIdPic">
                    <span slot="label">法人代表身份证 <small>请分别上传身份证人像面和国徽面</small></span>
                    <el-row :gutter="24">
                        <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                            <image-upload 
                                class="otherPic"
                                :url.sync="formData.corporationIdPic1" 
                                :urlFull.sync="formData.corporationIdPic1">
                                <span slot="image-slot">人像面</span>
                            </image-upload>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="editType == 1 ? 8 : 12">
                            <image-upload 
                                class="otherPic"
                                :url.sync="formData.corporationIdPic2" 
                                :urlFull.sync="formData.corporationIdPic2">
                                <span slot="image-slot">国徽面</span>
                            </image-upload>
                        </el-col>
                    </el-row>
                </el-form-item>    
            </div>

        </el-form>
    </div>
</template>

<script>
import ImageUpload from '@/components/ImageUpload';
import AuthTips from '@/components/AuthTips';

const defaultFormData = {
    authProperty:2,
    companyName:'',
    licenseId:'',
    licensePic:'',
    otherPic:'',
    corporationName:'',
    corporationId:'',
    corporationIdPic:'',
    corporationIdPic1:'',
    corporationIdPic2:'',
    corporationCodeName:'',
	businessType:0,
	registeredAddress:'',
	registeredCapitalStr:''
}
export default {
    props:{
        //1、认证管理 2、认领、注册流程
        editType:{
            type:[Number,String],
            default:1
        },
        userId:{
            type:[Number,String],
            default:''
        },
        //1：认领，2：注册
        authType:{
            type:[Number,String],
            default:2
        },
		id:{
		    type:[Number,String],
		    default:''
		},
		step1Data: {
		     type: Object,
		     default: null
		 },
    },
    components:{ImageUpload,AuthTips},
    data(){
        return{
            dataLoading: false,
            formData:{...defaultFormData},
            rules:{
                // companyName:[{required: true, message: '请填写企业名称'}],
                // licenseId:[{required: true, message: '请填写营业执照编号'}],
                // licensePic:[{required: true, message: '请上传营业执照'}],
            },
            otherPic1:'',
            otherPic2:'',
            // corporationIdPic1:'',
            // corporationIdPic2:'',
            userType:  this.$route.query.userType || this.$store.state.userInfo.userType,
            reFresh:false,
			claimData:{}
        }
    },
    created(){
        this.getAuthInfo();
    },
    methods:{
        getAuthInfo(){
			console.log(this.step1Data)
            this.dataLoading = true
            this.$request({
                url: "/user/register/getClaimInfo",
                params: {userId:this.userId},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            userId: result.userId || this.userId
                        }

                        const _otherPic = result.otherPic ? result.otherPic.split(',') : [];
                        this.otherPic1 = _otherPic[0] || '';
                        this.otherPic2 = _otherPic[1] || '';

                        const _corporationIdPic = result.corporationIdPic ? result.corporationIdPic.split(',') : [];
                        this.formData.corporationIdPic1 = _corporationIdPic[0] || '';
                        this.formData.corporationIdPic2 = _corporationIdPic[1] || '';
                        this.formData.authProperty = result.claimType || 2;

                        this.$emit('update:status',result.status || 0);

                    } else {
                        this.$message.error(msg || "获取认证信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取认证信息失败");
                })
                .finally(() => {
                    this.dataLoading = false;
                    this.reFresh = true;
                });
        },
        onSubmitAction(){
			console.log(this.editType)
			console.log(this.authType)
			//return
            this.$refs["formData"].validate((valid) => {
                if(valid){
                    const _otherPic = [];
                    if(this.otherPic1) _otherPic.push(this.otherPic1);
                    if(this.otherPic2) _otherPic.push(this.otherPic2);
                    this.formData.otherPic = _otherPic.join();

                    const _corporationIdPic = [];
                    if(this.formData.corporationIdPic1) _corporationIdPic.push(this.formData.corporationIdPic1);
                    if(this.formData.corporationIdPic2) _corporationIdPic.push(this.formData.corporationIdPic2);
                    this.formData.corporationIdPic = _corporationIdPic.join()
                    
					const _url = this.editType == 2 && this.authType == 2 ? "/user/register/claimAuth"  : this.editType == 2 && this.authType == 1 ?  "/user/register/claim" : "/user/register/commitAuth";
					console.log(_url)
					 // editType 1、认证管理 2、认领、注册流程	  /user/register/claim
                   
				  this.claimData = {
					...this.step1Data   
				  }
				  this.claimData.authInfo = {
					...this.formData
				  }
				  console.log(this.claimData)
				  
				 // return;
                    this.$request({
                        url:_url,
                        method: "POST",
                        data: {...this.claimData, type:this.authType, id:this.id ,userType:this.userType},
                    }).then( res => {
                        //处理成功回调
                        const { state, msg } = res.data;
                        if (state && state == 1) {

                            //注册\认领第二步
                            if( this.editType == 2 ){
                                //认领
                                if(this.authType == 1){
                                    this.autSuccess();
                                }                               
                            }
                            //认证
                            else{
                                this.autSuccess();
                            }
                        } else {
                            return this.$message.error(msg || "操作失败");
                        }
                    })
                }
            })
        },
        async onSubmit(){			
			if( this.formData.authProperty == 2 && this.formData.registeredCapitalStr == 0 ){
				return this.$message.error("注册资本必须大于0，请重新填写！");
			}
            if(this.editType == 2){
                this.$confirm('提交后请耐心等待审核,审核结果会通过短信发送到负责人手机', '认领', {
                    confirmButtonText: '确认提交',
                    cancelButtonText:'取消',
                    showClose:false,
                    customClass:'mpop',
                    closeOnClickModal: false,
                    closeOnPressEscape: false
                }).then( () => {
                    this.onSubmitAction();
                }).catch(() => {});
            }else{
                this.onSubmitAction();
            }
        },
        //注册成功
        // regSuccess(){
        //     this.$alert('<div>\
        //         欢迎入驻秀动，接下来您可以\
        //         <div class="MT20"><a class="color-theme" href="/manage/workbench/auth">【秀动认证】</a> 通过认证，才能发布演出</div>\
        //         <div class="MT10"><a class="color-theme" href="/manage/workbench/accountInfo">【完善更多信息】</a>  信息越完善，越能吸引用户</div>\
        //         <div class="MT10"><a class="color-theme" href="/manage/workbench/site/baseInfo">【进入小站随便逛逛】</a> </div>\
        //         </div>', '注册成功', {
        //         type: 'success',
        //         confirmButtonText: '确定',
        //         showCancelButton:false,
        //         showClose:false,
        //         dangerouslyUseHTMLString:true,
        //         customClass:'mpop',
        //     }).then( () => {
        //         this.$router.push('/manage/home');
        //     }).catch(() => {});
        // },
        //认证成功
        autSuccess(){
            const _msgTitle = this.editType == 2 ? '认领申请完成' : '提示';
            this.$confirm('我们已经收到您的认证申请，请耐心等待审核结果并保持手机畅通，以方便秀动工作人员进行必要的通知', _msgTitle, {
                type: 'success',
                confirmButtonText: '确定',
                showCancelButton:false,
                showClose:false,
                customClass:'mpop',
                closeOnClickModal: false,
                closeOnPressEscape: false
            }).then( () => {
                if(this.authType == 1){ //认领
                    this.$router.push('/manage/home');
                }else{// 认证
                     this.reFresh = false;
                    this.getAuthInfo();
                }
            }).catch(() => {});
        }
    }
}
</script>

<style lang="scss" scoped>
.page-group-box.group,.group{
    padding-bottom: 10px;
}
.licensePic{
    ::v-deep .image{
        width: 300px;
    }
}
.otherPic{
    ::v-deep .image{
        width: 170px;
        height: 150px;
    }
    ::v-deep .image-upload-content{
        height: 150px;
    }
}


</style>